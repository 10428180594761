@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}



.Toastify__toast-container--bottom-right {
  --toastify-toast-width: 270px;
}


.header {
  border-bottom: 1px solid #ededee;
}

.footer {
  border-top: 1px solid #ededee;
  margin-top: 20px;
}

.top-block {
  max-width: 1000px;
  min-height: 320px;
}

.code-examples {
  background-color: #282a3a;
  border-radius: 10px;
}

.code-w-big {
  /* min-width: 500px;
  max-width: 500px; */
  max-height: 300px;
  width: 100%;
  max-width: 650px;
}

.code-header {
  background-color: #111427;
  color: #ffffff;
  border-radius: 10px 10px 0 0;
  padding: 5px 10px 5px 10px;
  font-weight: normal;
  font-size: 16px;
}

.head-text {
  font-size: 40px;
  line-height: 45px;
}

.lang-button {
  color: white;
  padding: 7px 10px 7px 10px;
  
  width: auto;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.lang-button:hover {
  background-color: #393c51;
}

.code-examples  .selected {
  background-color: #393c51;
}

.main-title-block {
  width: 100%;
}

.code-area {
  border: 0;
  box-shadow: none;
  width: 100%;
  max-height: 150px;
  resize: none;
  height: 100%;
}

.code-area[disabled] {
  background-color: rgb(26, 28, 37);
  color: white;
}

.xx::-webkit-scrollbar {
  display: none;
}


.editor {
  display: inline-flex;
  gap: 10px;
  font-family: monospace;
  line-height: 21px;
  background: #282a3a;
  border-radius: 2px;
  padding: 5px;
  max-height: 150px;
  overflow-y: auto;
}

.txt {
  line-height: 21px;
  padding: 0;
  border: 0;
  background: #282a3a;
  color: #FFF;
  outline: none;
  resize: none;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-text-fill-color: #fff;
  white-space: pre;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.line-numbers {
  width: 20px;
  text-align: right;
}

.line-numbers span {
  counter-increment: linenumber;
}

.line-numbers span::before {
  content: counter(linenumber);
  display: block;
  color: #506882;
}

.txt::-webkit-scrollbar {
  display: none;
}

/* ===== EDITOR SCROLL ====== */
/* width */
.editor::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  display: none;
}

/* Track */
.editor::-webkit-scrollbar-track {
  background: #696a71;
}

/* Handle */
.editor::-webkit-scrollbar-thumb {
  background: #44485f;
}

/* Handle on hover */
.editor::-webkit-scrollbar-thumb:hover {
  background: #555;
} 


.copy-icon {
  cursor: pointer;
  padding: 5px;
  border-radius: 3px;
}

.copy-icon:hover {
  background-color: #282a3a;
}

.main-col {
  max-width: 1000px;
}

.price-block {
  background-color: #f7f7f7;
  border-radius: 10px;
}